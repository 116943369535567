.ql-size-small {
    font-size: 1em;
}

.ql-size-large {
    font-size: 2em;
}

.ql-size-huge {
    font-size: 3em;
}

.ql-font-serif {
    font-family: Georgia, Times New Roman, serif;
}

.ql-font-sans-serif {
    font-family: Helvetica, Arial, sans-serif;
}

.ql-font-monospace {
    font-family: Monaco, Courier New, monospace;
}

.ql-viewer {
    font-size: 1em;
    font-family: Helvetica, Arial, sans-serif;
    overflow-wrap: anywhere;
}

.ql-viewer a {
    text-decoration: underline;
    color : #007bff;
}
.ql-viewer a:hover {
    color : #0056b3;
}

.ql-viewer ul {
    list-style: inside;
    list-style-type: disc;
    padding-left: 1.5em;
}

.ql-viewer ol {
    list-style: inside;
    list-style-type: decimal;
    padding-left: 1.5em;
}

.ql-viewer .ql-align-center {
    text-align: center;
}

.ql-viewer .ql-align-right {
    text-align: right;
}

.ql-viewer .ql-align-left {
    text-align: left;
}

